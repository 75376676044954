<template>
  <div class="news-list">
    <el-table :show-header="false" :data="newsIndex">
      <el-table-column>
        <template #default="{ row: { txt, link } }">
          <el-link :href="link">{{txt}}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="date" class-name="news-date" />
    </el-table>
  </div>
</template>

<style lang="sass" scoped>
.news-list
  max-width: 800px
  margin: auto
  padding: 24px
  :deep(.news-date)
    font-size: 13px
    color: #909399
    text-align: end
</style>

<script>
export default {
  data() {
    return {
      newsIndex: [],
      jsonContent: null,
    }
  },
  async created() {
    const resp = await fetch("/news-index.json");
    this.newsIndex = await resp.json()
  },
}
</script>